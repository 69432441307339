import { Component } from "react";
import "./plans.scss";

import Header from "./header";
import ExpandTable from "./table/expandtable";
import wifi from "../assets/media/plans/wifi.png";
import Footer from "./footer";

export default class Plans extends Component {

  constructor() {
    super();
    this.state = {}
    this.state.showPopup = false;
  }

  changePopupState() {
    this.state.showPopup = !this.state.showPopup;
    this.forceUpdate();
  }

  getPopup = () => {
    if(this.state.showPopup) {
      return <div className='popup'>
        <div className='popup_inner'>
          <div>
          <button style={{'float':'right'}} onClick={() => this.changePopupState()}>x</button>
          <h1 style={{'text-align':'center'}}>Refund and Cancellation Policy</h1>
          <p style={{'font-size': '2em', 'padding':'2em 1em'}}>
            If you want to cancel the product and initiate a refund please contact to our customer care 8880322222.<br/>
            <br/>
            Our Policy for the cancellation and refund will be as follows:<br/><br/>
            
            <b>Cancellation Policy</b><br/>
            For Cancellations please contact to our customer care within 24 hours. <br/>
            Cancellation will not be possible once product is shipped from our end.<br/><br/>

            <b>Refund Policy</b><br/>
            If payment gets failed and any amount is deducted, it will be refunded to your source of payment with 5-7 business days. Please call us at our customer care if you face any issue regarding that.
            </p>
          </div>
        </div>
      </div>
    }
    return null;
    
  }

  render = () => {
    return (
      <>
        <Header for={this.props.match.params.for} hideButton={true} />
        
        <div className="plans-card">
          <div className="top-images">
            <img src={wifi} style={{ width: "50%" }} alt="wifi" />
          </div>
          <div className="plans-heading">
            <h1>Choose the plan</h1>
            <h1
              style={{
                fontSize: "2.6em",
                margin: "0",
                fontWeight: "normal",
                color: "#6D6B6B",
              }}
            >
              that best suits you
            </h1>
          </div>
          <ExpandTable />
          <p style={{ "font-size": "2em", "text-align": "right", "padding": "0 2em", "margin-top": "0"}}>WIOM users can also buy coupons of <br/> Rs. 10, 20 & 100 for 1, 5 & 30 Days of<br/>Internet at WIOM Internet points </p>
          <a style={{'font-size': '1.5em', 'margin': '1em'}} className={this.state.showPopup + ' 000'} onClick={() => this.changePopupState()}>Cancellation & Refund Policy</a>
          {this.getPopup()}
        </div>
        <Footer for={this.props.match.params.for} />
      </>
    );
  }
}
