import { Component, React } from "react";
import ReactPlayer from "react-player";
import videothumb from "../assets/media/video-thumb.png";
import "./youtube-thumb.scss";

export default class YTWrapper extends Component {
  
  start = (eve) => {
    if (document.documentElement.clientWidth < 540) {
      console.log('playing in popout')
      eve.stopPropagation();
      this.setSmallDeviceVideo()
    }
  }

  render = () => {
    let props = this.props;
    let site = props.for;
    let wStyle = null;
    let vStyle = null;
    if (props.size) {
      wStyle = {
        width: props.size
      }
      vStyle = {
        width: props.size,
        height: `calc(${props.size} * 0.6)`
      }
    }

    let lower = null, upper = null;
    if (props.subPos) {
      upper = <div className="yt-video-sub">
        {props.children}
      </div>
    } else {
      lower = <div className="yt-video-sub">
        {props.children}
      </div>
    }
    return (
      <div className="yt-wrapper" style={wStyle}>
        {upper}
        <div className="yt-video" style={vStyle}>
          <ReactPlayer
            className={props.class}
            height="100%"
            width="100%"
            url={props.url}
            muted={false}
            controls={true}
            playing
            light={props.thumb || videothumb}
            playIcon={
              <button onClick={(e) => { this.start(e) }} style={{ background: "transparent", border: "0", cursor: "pointer", fontSize: '1em' }} >
                <img src={`${process.env.PUBLIC_URL}/svgs/${site}_play.svg`} />
              </button>
            }
          />
        </div>
        {lower}
        {this.smallDeviceVideo}
      </div>
    );
  }

  setSmallDeviceVideo = () => {
    if (document.documentElement.clientWidth < 540) {
      let props = this.props;
      this.smallDeviceVideo = <div className="backdrop">
        <div className="close" onClick={() => { this.smallDeviceVideo = null; this.forceUpdate()} }>X</div>
        <div className="video">
          <ReactPlayer
            className={props.class}
            height="100%"
            width="100%"
            url={props.url}
            muted={false}
            controls={true}
            playing
          />
        </div>
      </div>
      this.forceUpdate();
    }
  }
}