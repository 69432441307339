import React from "react";
import languageIcon from "../assets/svgs/language_icon.svg";

const NavbarPartnerPlus = ({ onHelpClick, onLanguageClick ,curLang}) => {


    
  return (
    <nav
      style={{
        width:"100%",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "right",
        position: "sticky",
        top: 0,
        zIndex: 1000,
        marginRight:"64px"
      }}
    >


      {/* Right Section (Language Icon and Text) */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "12px",
        }}
      >
        <div
          style={{
            fontWeight: 600,
            fontSize: "14px",
            color: "#D9008D",
            lineHeight: "20px",
            cursor: "pointer",
          }}
          onClick={onHelpClick}
        >
          {curLang=="hindi"?"मदद":"Help"}
        </div>

        <img
          src={languageIcon}
          alt="Change Language"
          style={{
            width: "24px",
            height: "24px",
            cursor: "pointer",
          }}
          onClick={onLanguageClick}
        />
      </div>
    </nav>
  );
};

export default NavbarPartnerPlus;
