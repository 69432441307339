import { Component } from "react";
import { Link } from "react-router-dom";
import "./footer.scss";

export default class Footer extends Component {
  constructor() {
    super();
    this.fixed = "";
    window.addEventListener('app-resize', (e) => {
      this.ht()
    })
    window.addEventListener('dom-updated', (e) => {
      this.ht()
    })
  }

  ht() {
    this.fixed = "";
    this.forceUpdate();
    this.fixed = window.innerHeight > document.body.clientHeight ? " fixed" : "";
    //console.log("---- ", window.innerHeight, document.body.clientHeight, "---", this.fixed)
    this.forceUpdate();
  }
  render = () => {
    let site = this.props.for;
    return (
      <div className={"footer-bar"  + this.fixed}>
        <div className="hide-in-small navs">
          <a href="tel:8880322222"><img src={ `${process.env.PUBLIC_URL}/svgs/foot-call.svg`}/>888-03-22222</a>
          <a href="https://api.whatsapp.com/send?phone=918880322222"><img src={ `${process.env.PUBLIC_URL}/svgs/foot-whatsapp.svg`}/>888-03-22222</a>
          <a href="mailto:contact@i2e1.com"><img src={`${process.env.PUBLIC_URL}/svgs/foot-email.svg`} />contact@i2e1.com</a>
          <Link className="white-cta" to={`/${site}/learning-center/status_certification`}>PDO Status &amp; Certification</Link>
        </div>

        <div className="lower">
          <div className="hide-in-small">
            <a href="https://www.facebook.com/wiombyi2e1" target="_blank"><img src={ `${process.env.PUBLIC_URL}/svgs/facebook.svg` } /></a>
            <a href="https://twitter.com/i2e1official" target="_blank"><img src={ `${process.env.PUBLIC_URL}/svgs/twitter.svg` } /></a>
            <a href="https://www.instagram.com/i2e1_official/" target="_blank"><img src={ `${process.env.PUBLIC_URL}/svgs/insta.svg` } /></a>
          </div>
          <h2 className="hide-in-small">Wiom by i2e1, 2021</h2>
          <h1>© Omnia Information pvt. ltd</h1>
        </div>

        <div className="navs hide-in-small">
          <Link to={`/${site}/learning-center/blogs`}>Blogs</Link>
          <Link to={`/${site}/learning-center/knowledge_sharing`}>Knowledge sharing</Link>
          <Link to={`/${site}/learning-center/how_to_guides`}>'How to' guides</Link>
          <Link to={`/${site}/learning-center/faqs`}>FAQs</Link>
          <Link to={`/${site}/learning-center/news`}>In News</Link>
          <a target="_blank" href="/tnc.html">Terms & Conditions</a>
          <a target="_blank" href="/privacyPolicy.html">Privacy Policy</a>
          <a href="/tribe.html">Team</a>
        </div>
      </div>
    );
  }
};

