import { React } from "react";
import Header from "./header";
import "./screen-12.scss";

import aunty from "../assets/media/screen-12/aunty.png";
import linedots from "../assets/media/screen-12/lineanddots.png";
import div2img from "../assets/media/screen-12/div2img.png";
import div3img from "../assets/media/screen-12/div3img.png";
import div4img from "../assets/media/screen-12/div4img.png";
import div4_2img from "../assets/media/screen-12/div4_2img.png";
import div6_1img from "../assets/media/screen-12/div6_1img.png";
import div6_2img from "../assets/media/screen-12/div6_2img.png";
import div7img from "../assets/media/screen-12/div7img.png";
import div8img from "../assets/media/screen-12/div8img.png";
import div10img from "../assets/media/screen-12/div10img.png";

function Screen12() {
  return (
    <div className="scr12">
      <Header />
      <div className="screen12-main">
        <div className="heading">
          <div className="heading-title">
            <h1>A Digital Revolution across<br/>the next 500 million in India</h1>
            <div>
              <div className="pp-cir">
                <div className="circ"></div>
                <hr className="pp"></hr>
                <div className="circ"></div>
              </div>
              <h2>Take a guess on this one…</h2>
              <h2 style={{fontWeight: '100'}}>
                How do Indians in a low-income area carry 40 litres of water for
                5 kilometres in just 30 min?
              </h2>
              <div className="pp-cir">
                <div className="circ"></div>
                <hr className="pp"></hr>
                <div className="circ"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="screen12-content">
          <div className="first-div">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <div style={{ width: "30em" }}>
                <img src={aunty} style={{ width: "30em" }} />
              </div>
              <div
                style={{ width: "30%", margin: "0 2em", textAlign: "left" }}
              >
                <h1 style={{ margin: "0", fontSize: "4em" }}>
                  Are you thinking of a woman carrying pitchers on her head?
                </h1>
                <h1>?</h1>
                <h2>?</h2>
                <h3>?</h3>
                <h4>?</h4>
                <h5>?</h5>
                <h6>?</h6>
                <h1 style={{ margin: "0", fontSize: "4em", color: "#204191" }}>
                  <i>Well, think again…</i>
                </h1>
              </div>
            </div>
            <div style={{ marginTop: "10em", textAlign: "center" }}>
              <img src={linedots} style={{ width: "50%" }} />
            </div>
          </div>
          <div className="second-div">
            <h1>Introducing</h1>
            <h1>Tumblers on Wheels!</h1>
            <img src={div2img} />
            <p>
              With a simple crowbar like apparatus, people of all ages and
              gender can balance up to 40 litres of water and carry it easily on
              bicycles, daily.
            </p>
            <div style={{ marginTop: "6em", textAlign: "center" }}>
              <img src={linedots} style={{ width: "50%" }} />
            </div>
          </div>
          <div className="third-div">
            <div style={{ width: "50em", textAlign: "center" }}>
              <h1 style={{ fontSize: "3.5em", color: "#204191" }}>
                What’s also amazing is how quickly the concept has been adopted
                across multiple rural neighbourhoods
              </h1>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <img src={div3img} style={{ width: "40em" }} />
              <p
                style={{
                  width: "10em",
                  fontSize: "2.5em",
                  margin: "0 0 0 1em",
                }}
              >
                While this story looks like a prime example of how innovation is
                happening across all parts of our country, let’s put it in
                perspective with <br />
                <br />
                <span style={{ fontWeight: "bold" }}>
                  The Dark Knight rises.
                </span>
              </p>
            </div>
            <div style={{ marginTop: "6em", textAlign: "center" }}>
              <img src={linedots} style={{ width: "50%" }} />
            </div>
          </div>
          <div className="fourth-div">
            <div style={{ width: "55em", textAlign: "center" }}>
              <h1 style={{ fontSize: "3.25em", color: "#204191" }}>
                If you have watched this Nolan epic, you must have noticed how
                Selina Kyle foretells a very dark picture of anarchy driven by
                the rich-and-poor divide.
              </h1>
            </div>
            <img src={div4img} style={{ width: "60em" }} />
            <div style={{ width: "55em", textAlign: "left" }}>
              <p style={{ fontSize: "3.25em", color: "#204191" }}>
                <span style={{ fontWeight: "bold", color: "black" }}>
                  In a ball-room setting, she whispers in billionaire Bruce
                  Wayne’s ears
                </span>
                <br />
                <br />
                <span style={{ fontWeight: "lighter" }}>
                  "There's a storm coming, Mr. Wayne. You and your friends
                  better batten down the hatches, because when it hits, you're
                  all gonna wonder how you ever thought you could live so large
                  and leave so little for the rest of us."
                </span>
                <br />
                <br />
                <span
                  style={{
                    fontWeight: "lighter",
                    color: "black",
                    fontSize: "0.75em",
                  }}
                >
                  While in reality we haven’t hit anarchy, this divide is as
                  real as it gets:
                </span>
              </p>
            </div>
            <img src={div4_2img} style={{ width: "80%", margin: "3rem 0" }} />
            <div style={{ width: "100%", alignSelf: "left" }}>
              <h2 style={{ marginLeft: "15em" }}>
                Source:{" "}
                <a
                  href=""
                  style={{
                    color: "#F4693A",
                    textDecoration: "underline",
                  }}
                >
                  livemint
                </a>
              </h2>
            </div>
            <div style={{ marginTop: "6em", textAlign: "center" }}>
              <img src={linedots} style={{ width: "50%" }} />
            </div>
          </div>
          <div className="fifth-div">
            <div style={{ width: "48em" }}>
              <h1 style={{ fontWeight: "bold" }}>
                It is this divide that brings out two realities from the
                'Tumblers on Wheels' story:
              </h1>
            </div>
            <div
              style={{
                borderLeft: "0.5em solid #F4693A",
                borderRight: "0.5em solid #F4693A",
                width: "55em",
                margin: "2em 0",
              }}
            >
              <p style={{ fontSize: "2em", margin: "0 1em" }}>
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.3em",
                    color: "#F4693A",
                  }}
                >
                  1. At a philosophical level, the under-privileged segments
                  yearn for a change
                </span>
                , where they would do anything, by any means necessary to
                improve their quality of life
                <br />
                <br />
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.3em",
                    color: "#F4693A",
                  }}
                >
                  2. This India also acknowledges the power of information
                </span>{" "}
                in driving such a change. They are always in the hunt for any
                piece of information that can make their lives better, either by
                word of mouth, or as is in most cases, by WhatsApp. It was
                ‘access to information’ that made it possible for low-income
                households to quickly adopt the ‘Tumbler on Wheels’ idea.
              </p>
            </div>
            <div
              style={{
                borderBottom: "0.5em solid",
                color: "#F4693A",
                width: "10em",
                margin: "5em 0",
              }}
            ></div>
            <div
              style={{
                width: "55em",
                margin: "2em 0",
              }}
            >
              <p style={{ fontSize: "2em", margin: "0 1em" }}>
                Decisions and opinions driven from access to information open
                channels to development that were previously inaccessible -
                whether it’s online education or e-commerce or access to ideas
                and schemes that can enable growth.
                <br />
                <br /> This makes Dreaming Big in life a lot more affordable
                across lower income groups.
                <br />
                <br />
                And with information readily available online, the next 500
                million Indians are turning to Internet as a necessary
                commodity. A commodity that holds a lot of potential to help
                them improve their quality of life.
                <br />
                <br />
                <span style={{ fontWeight: "bold" }}>
                  This demand for internet is sowing seeds to a different kind
                  of a revolution…
                </span>
              </p>
            </div>
            <div style={{ marginTop: "6em", textAlign: "center" }}>
              <img src={linedots} style={{ width: "50%" }} />
            </div>
          </div>
          <div className="sixth-div">
            <div style={{ width: "45em", textAlign: "left" }}>
              <h1 style={{ fontSize: "2.25em" }}>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "#204191",
                    fontSize: "1.5em",
                  }}
                >
                  Do you remember the time when the ‘Sachet revolution’ gripped
                  India,
                </span>
                when the lower income households started getting access to
                ‘previously in-accessible, expensive consumer brands’, via
                sachets? The sachet concept introduced these brands at lower
                prices, albeit at lower unit quantities.
              </h1>
            </div>
            <img src={div6_1img} style={{ margin: "2em 0", width: "50%" }} />
            <div style={{ width: "45em", textAlign: "center" }}>
              <h1 style={{ fontSize: "3.25em", color: "#204191" }}>
                Now imagine Internet being made available in sachets, or Wi-Fi
                coupons.
              </h1>
            </div>
            <img src={div6_2img} style={{ margin: "2em 0", width: "50%" }} />
            <div style={{ width: "45em" }}>
              <h1 style={{ fontSize: "2.25em", fontWeight: "normal" }}>
                This is a concept that PM WANI and WIOM are introducing the next
                500 million Indians to.
                <br />
                <br />
                <span style={{ fontWeight: "bold" }}>
                  And early signs of a change in their quality of lives are
                  already becoming visible…
                </span>
              </h1>
            </div>
            <div style={{ marginTop: "6em", textAlign: "center" }}>
              <img src={linedots} style={{ width: "50%" }} />
            </div>
          </div>
          <div className="seventh-div">
            <div style={{ width: "48em", textAlign: "center" }}>
              <h1
                style={{ fontSize: "2em", fontWeight: "normal", margin: "0" }}
              >
                Prem Sharma, owner of a small grocery shop, recently
              </h1>
              <h1 style={{ fontSize: "2.75em", color: "#204191", margin: "0" }}>
                DOUBLED his monthly income
              </h1>
            </div>
            <img src={div7img} style={{ width: "40%" }} />
            <div
              style={{
                width: "45em",
                margin: "2em 0",
              }}
            >
              <p style={{ fontSize: "1.75em", margin: "0 1em" }}>
                Prem is like any other small businessman in India: He could
                never dream big for his business because making rent for his
                shop every month was a challenge in itself. On top of that,
                COVID-19 took a heavy toll on business.
                <br />
                <br /> With the launch of PM WANI, Prem realised that internet
                is a necessary commodity for many.
                <br />
                <br /> He procured a broadband connection and started cashing in
                by re-selling his broadband bandwidth in the form of Wi-Fi
                coupons to his customers.
                <br />
                <br /> Now customers from next door households started
                connecting to his Wi-Fi signal to access internet. Some
                customers also started to linger around the shop. So Prem set up
                a few tables and chairs, started serving tea and snacks. The
                longer these Wi-Fi users stayed, the more they purchased. Prem’s
                popularity sky rocketed in his neighbourhood.
              </p>
            </div>
            <div style={{ marginTop: "6em", textAlign: "center" }}>
              <img src={linedots} style={{ width: "50%" }} />
            </div>
          </div>
          <div className="eighth-div">
            <div style={{ width: "48em", textAlign: "center" }}>
              <h1
                style={{ fontSize: "2em", fontWeight: "normal", margin: "0" }}
              >
                Getting an{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    color: "#204191",
                    fontSize: "1.5em",
                  }}
                >
                  easier and affordable
                </span>{" "}
                access to online education is no more a challenge for Anuradha
                and many like her...
              </h1>
            </div>
            <img src={div8img} style={{ width: "40%" }} />
            <div
              style={{
                width: "45em",
                margin: "2em 0",
              }}
            >
              <p style={{ fontSize: "1.75em", margin: "0 1em" }}>
                Sushma, a 65-year-old resident in Kusum Pahadi, was always
                worried about her 16-year-old granddaughter Anuradha’s security
                and struggles.
                <br />
                <br /> Anuradha travels 5 km daily in the evening to get online
                tuitions at a relative’s place, which incidentally has a
                broadband connection. Sushma’s 4G connection was never enough to
                facilitate Anuradha’s study.
                <br />
                <br /> But with the Wi-Fi coupons purchased from a next-door
                mom-and-pop store, Anuradha is now able to study online, at
                home, by connecting to the Wi-Fi signal of the store.
              </p>
            </div>
            <div style={{ marginTop: "6em", textAlign: "center" }}>
              <img src={linedots} style={{ width: "50%" }} />
            </div>
          </div>
          <div className="ninth-div">
            <div
              style={{
                width: "45em",
                margin: "2em 0",
              }}
            >
              <p style={{ fontSize: "1.75em", margin: "0 1em" }}>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "#204191",
                    fontSize: "1.5em",
                  }}
                >
                  But why would anyone consider Wi-Fi coupons over 4G?
                </span>
                <br />
                <br />
                <span
                  style={{
                    fontWeight: "bold",
                    color: "#204191",
                    fontSize: "1.5em",
                  }}
                >
                  Because these Wi-Fi coupons are way cheaper than 4G!
                </span>
                <br />
                <br />
                The cheapest 4G connection (Rs. 149 for 28 days) gives a daily
                access of 1 GB, which limits the possibilities of internet
                consumption, while consistently disrupting user experience in a
                family of 4 or more ({" "}
                <a
                  href=""
                  style={{
                    fontWeight: "bold",
                    color: "#204191",
                  }}
                >
                  read here
                </a>
                ).
                <br />
                <br /> Enter Wi-Fi coupons of Prem’s shop, that give a monthly
                access to unlimited data at just Rs. 50 or less, while at the
                same time, helping Prem and many like him increase their
                incomes.
                <br />
                <br />
                <span
                  style={{
                    fontWeight: "bold",
                    color: "#204191",
                    fontSize: "1.5em",
                  }}
                >
                  This is a new wave of digital empowerment that is giving rise
                  to a new form of internet economy.
                </span>
                <br />
                <br />
                This economy is enabling shopkeepers become digital
                entrepreneurs, while providing affordable internet access across
                low-income households.
                <br />
                <br />
                In 2019, it was observed that a 10% increase in internet traffic
                led to a{" "}
                <a
                  href=""
                  style={{
                    fontWeight: "bold",
                    color: "#204191",
                    textDecoration: "underline",
                  }}
                >
                  3.3% increase in GDP
                </a>
                . Now just imagine the kind of impact disruptive tech can create
                by tapping the potential across the next 500 million Indians.
              </p>
            </div>
            <div style={{ marginTop: "6em", textAlign: "center" }}>
              <img src={linedots} style={{ width: "50%" }} />
            </div>
          </div>
          <div className="tenth-div">
            <div style={{ width: "48em", textAlign: "left" }}>
              <h1
                style={{
                  fontSize: "2.5em",
                  fontWeight: "bold",
                  margin: "0",
                  color: "#204191",
                }}
              >
                WIOM’s WANI compliant tech is making this happen across Kusumpur
                Pahadi and many such areas
              </h1>
            </div>
            <img src={div10img} style={{ width: "40%" }} />
            <div
              style={{
                width: "50em",
                margin: "2em 0",
              }}
            >
              <p style={{ fontSize: "1.75em", margin: "0 1em" }}>
                Kusumpur Pahadi is one of the many low-income neighbourhoods of
                Delhi, where everyone understands the need of internet. Yet,
                residents in this area yearn for more data, affordable data,
                faster speeds…
                <br />
                <br /> More than what 4G has to offer.
              </p>
            </div>
          </div>
          <div className="footer-bar">
            <div style={{ width: "55%" }}>
              <h1 style={{ fontSize: "10em", margin: "0", fontWeight: '100' }}>Say Hi,</h1>
              <h1 style={{ fontSize: "5em", margin: "0" }}>
                If you are also looking
              </h1>
              <h1 style={{ fontSize: "5em", margin: "0" }}>
                to create an impact with us.
              </h1>
              <button>Contact us</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Screen12;
