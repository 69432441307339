import { React } from "react";
import AccordianPlan from "../accordian-plan";
import "./expandtable.scss";
import tickorange from "../../assets/media/tick-orange.png";
import tickpink from "../../assets/media/tick-pink.png";
import tickgreen from "../../assets/media/tick-green.png";
import cross from "../../assets/media/cross.png";

const datasimple = [
  {
    title: " PM WANI Compliance ",
    wiommagic: tickorange,
    wiomprime: tickpink,
  },
  {
    title: " Sell internet plans ",
    wiommagic: tickorange,
    wiomprime: cross,
  },
];

const dataaccordian_1 = {
  title: "Smart marketing",
  number: "1",
  info: [
    {
      feature: " Basic user report  ",
      wiommagic: tickorange,
      wiomprime: cross,
    },
    {
      feature: " Detailed user report  ",
      wiommagic: cross,
      wiomprime: cross,
    },
    {
      feature: " Custom landing page  ",
      wiommagic: cross,
      wiomprime: cross,
    },
    {
      feature: " Promotions on login page  ",
      wiommagic: cross,
      wiomprime: cross,
    },
    {
      feature: " Customer data for marketing ",
      wiommagic: cross,
      wiomprime: cross,
    },
  ],
};

const dataaccordian_2 = {
  title: "100% Data control",
  number: "2",
  info: [
    {
      feature: " Phone No. OTP login   ",
      wiommagic: tickorange,
      wiomprime: tickpink,
    },
    {
      feature: "Data restriction/ day   ",
      wiommagic: tickorange,
      wiomprime: tickpink,
    },
    {
      feature: "Custom Wi-Fi name/password   ",
      wiommagic: tickorange,
      wiomprime: tickpink,
    },
    {
      feature: " User level data control   ",
      wiommagic: tickorange,
      wiomprime: tickpink,
    },
    {
      feature: " Limit for session duration  ",
      wiommagic: tickorange,
      wiomprime: tickpink,
    },
    {
      feature: " Limit for number of devices/user   ",
      wiommagic: tickorange,
      wiomprime: tickpink,
    },
    {
      feature: " Automatic login   ",
      wiommagic: tickorange,
      wiomprime: tickpink,
    },
    {
      feature: " User blocking + VIP access  ",
      wiommagic: tickorange,
      wiomprime: tickpink,
    },
  ],
};

function ExpandTable() {
  let jump = function (url) {
    window.open(url, '_blank')
  }
  return (
    <div className="expandtable-main">
      <table className="main-table">
        <td>
          <th class="plan-title"></th>
          <th>
            <h1 style={{ color: "#F4693A" }}>WIOM Magic PDO</h1>
          </th>
          <th>
            <h1 style={{ color: "#EF4F82" }}>WIOM Gold</h1>
          </th>
        </td>
        {datasimple.map((value) => {
          return (
            <td>
              <tr className="plan-title">
                <h1>{value.title}</h1>
              </tr>
              <tr>
                <img src={value.wiommagic} />
              </tr>
              <tr>
                <img src={value.wiomprime} />
              </tr>
            </td>
          );
        })}
        <td>
          <tr>
            <AccordianPlan data={dataaccordian_1} />
          </tr>
        </td>
        <td>
          <tr>
            <AccordianPlan data={dataaccordian_2} />
          </tr>
        </td>
        <td>
          <tr className="plan-title">
            <h1>1 year subscription</h1>
          </tr>
          <tr>
            <img src={tickgreen} />
          </tr>
          <tr>
            <p style={{ "font-size": "1.5em"}}>Monthly Rental Plan</p>
          </tr>
        </td>
        <td>
          <th className="plan-title"></th>
          <th>
            <button
              className="price-button primary"
              style={{
                background: "#F4693A",
                borderRadius: "5px",
              }}
              onClick={ () => jump('https://wiom.in/wiompayment/subscriptions?pid=10003')}
            >
              <h1>Rs. 4,999</h1>
            </button>
          </th>
          <th>
            <button
              className="price-button primary"
              style={{
                background: "#F4693A",
                borderRadius: "5px",
              }}
              onClick={ () => jump('https://wiom.in/buy/post-login/device-id')}
            >
              <h1>Pay Now</h1>
            </button>
          </th>
        </td>
        
      </table>
    </div>
  );
}

export default ExpandTable;
