import React, { Component } from "react";
import "./header.scss";
import logo from "../assets/svgs/wiom_logo.svg";
import callImg from "../assets/svgs/ic_baseline-call.svg";
import { Link } from "react-router-dom";
import Drawer from "./drawer/drawer";

class Header extends Component {
  constructor() {
    super();
    this.state = {};
    this.state.showPopup = false;
  }

  call = () => {
    window.open("tel:8880322222");
  };

  getFloatingButton = (site) => {
    if (this.props.hideButton) {
      return null;
    }
    let order = (
      <Link to={`/${site}/order`} className="order-wiom">
        <button
          onClick={this.removeOrderButton}
          className="button primary pink noshadow"
        >
          Order Wiom
        </button>
      </Link>
    );
    if (site == "partner") {
      order = (
        <Link to={`/${site}/form`} className="order-wiom">
          <button
            onClick={this.removeOrderButton}
            className="button primary blue noshadow"
          >
            Partner with us
          </button>
        </Link>
      );
    }
    return <div className="floating-button visible-in-small">{order}</div>;
  };
  changePopupState() {
    this.state.showPopup = !this.state.showPopup;
    this.forceUpdate();
  }

  getPopup = () => {
    if (this.state.showPopup) {
      return (
        <div className="popup">
          <div className="popup_inner">
            <div>
              <button
                style={{ float: "right" }}
                onClick={() => this.changePopupState()}
              >
                x
              </button>
              <h1 style={{ "text-align": "center" }}>About Us</h1>
              <p style={{ "font-size": "2em", padding: "2em 1em" }}>
                Since last year, the Government of India has allowed individuals
                to share their extra internet with others and make money.
                However, this can only be done using a new class of licensed
                players called PDOAs.
                <br />
                <br />
                Wiom is one of the first and largest PDOA in India. We provide
                our hardware and software stack to customers so that they can
                use our services and share their internet. The company has been
                covered in media extensively.
                <a href="/pdo/home">Click here to read more</a>
              </p>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  render() {
    let selected = null;
    if (this.props.location && this.props.location.selected)
      selected = this.props.location.selected;
    else if (this.props.selected) {
      selected = this.props.selected;
    }
    let site = this.props.for;
    let order = (
      <Link to={`/${site}/order`} className="order-wiom">
        <button
          onClick={this.removeOrderButton}
          className="button primary pink noshadow"
        >
          Order Wiom
        </button>
      </Link>
    );
    if (site == "partner") {
      order = (
        <Link to={`/${site}/form`} className="order-wiom">
          <button
            onClick={this.removeOrderButton}
            className="button primary blue noshadow"
          >
            Partner with us
          </button>
        </Link>
      );
    }
    if (this.props.hideButton) {
      order = null;
    }
    let nav = (
      <div className="navs">
        <Drawer for={site}></Drawer>
        <Link
          onClick={() => this.changePopupState()}
          className={site + (selected == "home" ? " selected" : "")}
        >
          About Us
        </Link>
        <Link
          to={{
            pathname: `/${site}/learning-center/blogs`,
            selected: "learning-center",
          }}
          className={site + (selected == "learning-center" ? " selected" : "")}
        >
          Learning Center
        </Link>
        <a
          href={
            site == "partner"
              ? "https://www.i2e1.in/client/index"
              : "https://admin.wiom.in"
          }
          target="blank"
        >
          Admin login
        </a>
        {order}
      </div>
    );
    if (this.props.nav == "0") {
      nav = null;
    }

    return (
      <>
        {this.getFloatingButton(site)}
        <div
          id="header"
          className={"nav-bar " + (this.props.bg ? this.props.bg : "")}
        >
          <Link to="/" className="logo">
            <img src={logo} alt="Wiom-Logo" />
          </Link>
          {nav}
          <div className={"navs"}>
            {/* <Link onClick={() => this.changePopupState()} className={'pdo'}>About Us</Link> */}
          </div>
          <div className="header-content-div">
            <div
              className="fc center"
              style={{ borderRadius: "12px", overflow: "hidden" }}
            >
              <a
                href="tribe.html"
                class="header-button"
                style={{ padding: "10px", borderRadius: 0 }}
                langId="meet_the_tribe"
              >
                Meet the Tribe
              </a>
            </div>

            <div
              className="fc center"
              style={{ borderRadius: "12px", overflow: "hidden" }}
            >
              <div
                onClick={this.call}
                class="icon-button"
                style={{ gap: "0.5em", alignItems: "center", height: "100%" }}
              >
                <div>
                  <img
                    src={callImg}
                    style={{ width: "auto", paddingTop: "0.45em" }}
                    alt=""
                  />
                </div>
                <div style={{ fontWeight: "700", whiteSpace: "nowrap" }}>
                  888 03 22222
                </div>
              </div>
            </div>
            <div
              className="fc center"
              style={{ borderRadius: "12px", overflow: "hidden" }}
            >
              <Link
                to="/partnerPlus"
                className="icon-button"
                style={{
                  gap: "0.5em",
                  alignItems: "center",
                  height: "100%",
                  textDecoration: "none",
                }}
              >
                <div
                  style={{
                    color: "#D9008D",
                    fontWeight: "700",
                    whiteSpace: "nowrap",
                    padding:"10px"
                  }}
                >
                  Wiom Partner +
                </div>
              </Link>
            </div>
          </div>
          {this.getPopup()}
        </div>
      </>
    );
  }
}
export default Header;
