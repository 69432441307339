import React, { useState, useEffect } from "react";
import Lottie from "react-lottie";
import successAnimation from "../assets/animation/greensuccess.json"; // Replace with your Lottie animation file
import successImage from "../assets/svgs/Frame.svg"; // Replace with your image file
import NavbarPartnerPlus from "./NavbarPartnePlus";
import { useLocation } from "react-router-dom";


const PartnerPlusDownload = ({curLang}) => {
  const location = useLocation();
  const [stage, setStage] = useState("animation"); // "animation" or "newContent"
  const [fade, setFade] = useState(true); // For fade transitions
  const queryParams = new URLSearchParams(location.search);
  const initialLanguage = queryParams.get("curLang") || "hindi";

  const [language, setLanguage] = useState(initialLanguage);
  const handleCall = () => {
    window.open("tel:8880322222");
  };
  const handleLanguageChange = () => {
    setLanguage((prevLanguage) =>
      prevLanguage === "hindi" ? "english" : "hindi"
    );
    console.log(language);
  };
  const textContent = {
    hindi: {
      loadingOne:"व्योम पार्टनर प्लस ऐप",
      loadingTwo:"इनस्टॉल हो गयी है",
      appInstalled: "पार्टनर प्लस ऐप इनस्टॉल हो गयी है",
      openApp: "अभी व्योम पार्टनर प्लस ऐप खोलें",
      growBusiness: "अपना बिजनेस बढ़ाने के लिए",
      appName: "व्योम पार्टनर प्लस ऐप",
    },
    english: {
      loadingOne:"Wiom partner plus",
      loadingTwo:"is now app installed",
      appInstalled: "Wiom partner plus app is now installed",
      openApp: "the Wiom partner plus app",
      growBusiness: "To grow your business open",
      appName: "the Wiom partner plus app",
    },
  };

  const defaultOptions = {
    loop: false, // Play once
    autoplay: true, // Start automatically
    animationData: successAnimation, // JSON animation file
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    // Set fade-out and then show new content
    const animationDuration = 2800; // Example duration for animation (3 seconds)

    // Fade out the current content
    setTimeout(() => setFade(false), animationDuration);

    // Switch to new content after fade-out
    setTimeout(() => {
      setStage("newContent");
      setFade(true); // Fade in the new content
    }, animationDuration + 700); // Add 1 second for fade-out transition
  }, []);

  return (
    <div
      style={{
        // height:"100%",
        backgroundColor: "#FAF9FC",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        // justifyContent: "center", // Align content to the top
        alignItems: "center",
        padding: "10px 20px",
        textAlign: "center",
        transition: "opacity 1s ease-in-out", // Smooth fade effect
        opacity: fade ? 1 : 0, // Toggle visibility based on fade state
        // transform: "translateY(-5%)", // Move content slightly upward
      }}
    >
      {stage === "animation" ? (
        <>
          {/* Animation */}
          <div
            style={{
              width: "50%",
              maxWidth: "300px",
              marginTop: "160px",
            }}
          >
            <Lottie options={defaultOptions} />
          </div>

          {/* Text Below Animation */}
          <div
            style={{
              fontFamily: "Noto Sans",
              fontSize: "20px",
              fontWeight: "700",
              lineHeight: "28px",
              color: "#333",
              marginTop: "20px",
              textAlign: "center",
            }}
          >
            {textContent[language].loadingOne}
          </div>

          <div
            style={{
              fontFamily: "Noto Sans",
              fontSize: "20px",
              fontWeight: "700",
              lineHeight: "28px",
              color: "#333",
              marginTop: "8px",
              textAlign: "center",
            }}
          >
            {textContent[language].loadingTwo}
          </div>
        </>
      ) : (
        <>
          {/* Navbar appears only after animation */}
          <div
            style={{
              position: "sticky",
              top: 0,
              width: "100%",
              zIndex: 1000,
              marginBottom: "60px",
            }}
          >
            <NavbarPartnerPlus
              onHelpClick={handleCall}
              onLanguageClick={handleLanguageChange}
              curLang={language}
            />
          </div>

          <div
            style={{
              fontFamily: "Noto Sans",
              fontSize: "20px",
              fontWeight: "400",
              lineHeight: "28px",
              color: "#333",
              margin: "20px 10px",
              textAlign: "center",
              color: "#161021",
            }}
          >
              {textContent[language].appInstalled} 
          </div>
          {/* New Image */}
          <img
            src={successImage}
            alt="Success"
            style={{
              width: "90%",
              maxWidth: "300px",
              height: "auto",
              marginBottom: "20px",
            }}
          />

          {/* New Text */}
          <div
            style={{
              fontFamily: "Noto Sans",
              fontSize: "20px",
              fontWeight: "700",
              lineHeight: "28px",
              color: "#333",
              marginTop: "20px",
              textAlign: "center",
              color: "#161021",
            }}
          >
            {textContent[language].growBusiness}{" "}
          </div>
          <div
            style={{
              fontFamily: "Noto Sans",
              fontSize: "20px",
              fontWeight: "700",
              lineHeight: "28px",
              color: "#333",
              marginTop: "5px",
              textAlign: "center",
              color: "#161021",
            }}
          >
            {textContent[language].openApp}{" "}
          </div>
        </>
      )}
    </div>
  );
};

export default PartnerPlusDownload;
