import "./home.scss";
import incubated_logo from "../assets/media/incubated_logo.png";
import Header from "./header.js";
import Footer from "./footer.js";
import { Component } from "react";
import pagehomelogo1 from "../assets/media/pagehomelogo1.png";
import pagehomelogo2 from "../assets/media/pagehomelogo2.png";
import YTWrapper from "./yt-video";
import Publishing from "./publishing";
import { Link } from "react-router-dom";

export default class Home extends Component {
  isSmallDevice = null;
  carousel = null;
  selectedCarousalItem = null;
  constructor() {
    super();
    this.homeItems = [
      { img: `${process.env.PUBLIC_URL}/carousel_1.png`, selected: true },
      { img: `${process.env.PUBLIC_URL}/carousel_2.png`, selected: false },
    ]

    window.addEventListener('app-resize', (e) => {
      this.forceUpdate()
    })
  }
  
  renderPdo(site) {
    return (
      <div className="home pdo">
        <div className="heading">
          <img className="head-back" src={`${process.env.PUBLIC_URL}/bg_home_pdo.jpg` }/>
          <a className="pdo-arrow" href="#make_your_dreams"><img  src={`${process.env.PUBLIC_URL}/svgs/home_down.svg`}/></a>
        </div>
        <div className="heading-line">
          <div style={{ fontSize: "4em", fontWeight: "bold" }}>
            Share Internet.
            <br />
            Make Money. Daily.
          </div>
        </div>
        
        <div className="content" style={{ position: "relative", zIndex: "0" }}>
          <div className="card" id="make_your_dreams">
            <div className="head">
              <h1>
                Make your dreams<br/>come true with wiom
              </h1>
            </div>
            <div className="details">
              <YTWrapper for={site} 
              url="https://youtu.be/qg5F9oTXWok" 
              thumb={ `${process.env.PUBLIC_URL}/thumbs/earn_with_wifi.png` }>
                <h1 style={{ color: "#000000", margin: '.15em 0' }}>Earn with Wi-Fi</h1>
                <h2 style={{ color: "#6D6B6B", margin: '0', fontWeight: 'normal' }}>
                  <span style={{ color: "#000000", fontWeight: 'bold' }}>PDO Wi-Fi</span> solution
                  that helps shop owners earn up-to Rs. 1,000 daily
                </h2>
              </YTWrapper>

              <Link to="/pdo/order" className="seperator">
                <img style={{width: '100%'}} src={`${process.env.PUBLIC_URL}/svgs/compare_files.svg`}/>
              </Link>
              
              <YTWrapper for={site}
                url="https://youtu.be/-Ndo0UvWCDc"
                thumb={ `${process.env.PUBLIC_URL}/thumbs/win_customer_loyality.png` }>
                <h1 style={{ color: "#000000", margin: '.15em 0' }}>Win customer loyalty</h1>
                <h2 style={{ color: "#6D6B6B", margin: '0', fontWeight: 'normal' }}>
                  <span style={{ color: "#000000", fontWeight: 'bold' }}>Customer Wi-Fi </span>
                  solution for a branded Wi-Fi experience, smart marketing
                </h2>
              </YTWrapper>
            </div>
          </div>

          <div className="card no-border">
            <div className="ext-border hide-in-small"></div>
            <div className="head">
              <h1 style={{textAlign: 'justify'}}>Bringing 10,000+ retailers<br/>closer to their dreams</h1>
              <img
                src={incubated_logo}
                className="hide-in-small"
                style={{ width: "20rem", height: "fit-content" }}
              />
            </div>

            <img
              className="no_1_sticker visible-in-small"
              src={`${process.env.PUBLIC_URL}/no-1.png`}
              alt="No-1logo"
              style={{
                width: "5.5em",
                position: "absolute",
                top: "-1em",
                right: "-1em",
              }}
            />
            <img
              src={incubated_logo}
              className="visible-in-small"
              style={{
                width: "7em",
                position: "absolute",
                bottom: ".5em",
                right: "0",
              }}
            />
            <div className="details start">
              <YTWrapper for={site} 
               url="https://youtu.be/HCdKZckJP6o"
               thumb={ `${process.env.PUBLIC_URL}/thumbs/hear_it_from_kishan.png`}>
                <span className="g-cir-img"><img src={pagehomelogo1} /></span>
                <div>
                  Kishan got a
                  <span> major boost to his income </span>
                  within just 2 weeks of becoming a PDO
                </div>
              </YTWrapper>

              <YTWrapper for={site} 
               url="https://youtu.be/6nvt0VXdXJo"
               thumb={ `${process.env.PUBLIC_URL}/thumbs/hear_it_from_baadal.png` }>
                <span className="g-cir-img"><img src={pagehomelogo2} /></span>
                <div>
                  Baadal’s business has become very popular and he is
                  <span> making an impact in his community</span>
                </div>
              </YTWrapper>
              
              <img
                className="no_1_sticker hide-in-small"
                src={`${process.env.PUBLIC_URL}/no-1.png`}
                alt="No-1logo"
                style={{ width: "20rem" }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  carLeft() {
    let root = document.querySelector(':root');
    let cl = root.style.getPropertyValue('--car-left');
    console.log("cl --- ", cl)
    if (!cl || cl <= 0) {
      cl = 100
    } else {
      cl = 0
    }
    root.style.setProperty('--car-left', cl);
    setInterval(() => {
      this.carLeft()
    }, 1000);
  }

  
  setupHomeCarousel = () => {
    if (document.body.clientWidth <= 540) {
      console.log(this.homeItems)
      let hmItems = []
      let hmDots = []
      this.homeItems.forEach((item, key) => {
        hmItems.push(<div id={ "slide-" + (key + 1)} className={"bgm car" + (item.selected ? " active" : "")} key={"carousel_" + key}>
          <img src={item.img} />
        </div>)
        hmDots.push(<a className="dotss" href={"#slide-" + (key + 1)}>o</a>)
      })
      this.carousel = <div className="slider">
          <div className="slides">
            {hmItems}
          </div>
          {hmDots}
        </div>
    } else {
      this.carousel = (<>
        <div className="heading">
          <img className="head-back" src={`${process.env.PUBLIC_URL}/bg_home_partner.png` }/>
        </div>
        <div
          style={{
            zIndex: 1,
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
          }}
        >
          <div className="heading-line">
            <div style={{ fontSize: "2.2em", fontWeight: "bold" }}>
              Monetize with
            </div>
            <div style={{ fontSize: "3.3em", fontWeight: "bold" }}>
              10 Million
            </div>
            <div style={{ fontSize: "2.2em", fontWeight: "bold" }}>
              Small Business Owners
            </div>
          </div>
          <div className="heading-line">
            <div style={{ fontSize: "2em", fontWeight: "bold" }}>Connect</div>
            <div style={{ fontSize: "3em", fontWeight: "bold" }}>
              1.3 Billion
            </div>
            <div style={{ fontSize: "2em", fontWeight: "bold" }}>
              Indians with Internet
            </div>
          </div>
        </div>
        </>)
        
    }
    console.log(this.carousel)
  }

  renderPartner(site) {
    this.isSmallDevice = document.body.clientWidth <= 540 ? " start" : "";
    this.setupHomeCarousel();
    return (
      <div className="home">
        {this.carousel}
        <div className="content">
          <div className="hide-in-small" style={{ textAlign: "center" }}>
            <Link to={"/partner/form/"}>
              <button
                className="button primary blue noshadow"
                style={{ width: '12em',fontSize: '1.2em' }}
              >
                Partner with us
              </button>
            </Link>
          </div>
          <div className="card">
            <div className="head">
              <h1 style={{ margin: "auto", maxWidth: "fit-content" }}>
                <span>
                  Drive Growth across
                  <br />
                  Small Businesses
                </span>
                <hr
                  style={{
                    margin: ".15em",
                    padding: "0",
                    borderColor: "transparent",
                  }}
                />
                <span style={{ color: "#6D6B6B", fontSize: '.9em' }}>
                  With PM WANI complaint WIOM Wi-Fi solution
                </span>
              </h1>
            </div>
            <div className={"details" + this.isSmallDevice}>
              <div style={{textAlign: 'left'}}>
                <img
                  style={{ width: "2.5em" }}
                  src={`${process.env.PUBLIC_URL}/svgs/misile.svg`}
                />
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "2em",
                    marginBottom: ".5em",
                  }}
                >
                  <span style={{ textDecoration: "underline" }}>Get.</span>
                  <span style={{ color: "#6D6B6B" }}> Set. </span>
                  <span style={{ color: "#6D6B6B" }}>Go.</span>
                </div>
                <div className="list-style">
                  <div className="list-h">
                    Set up WANI solution in just 5 minutes
                  </div>
                  <ul>
                    <li>Plug and play Wi-Fi device</li>
                  </ul>
                </div>
                <div className="list-style">
                  <div className="list-h">100% Cloud Hosted, Zero hassles</div>
                  <ul>
                    <li>High speed AP + Controller</li>
                    <li>Cloud based network management system</li>
                    <li>Voucher management system</li>
                    <li>Compliance user logs</li>
                  </ul>
                </div>

                <YTWrapper for={site}
                  subPos="top"
                  url="https://youtu.be/80H_eNHPjrA"
                  thumb={ `${process.env.PUBLIC_URL}/thumbs/robust_technology_for_10_milliom_dreams.png`} >
                  View detailed video below
                </YTWrapper>
                
              </div>
              <img
                className="seperator-partner hide-in-small"
                style={{ height: "20em" }}
                src={`${process.env.PUBLIC_URL}/svgs/partner_sep.svg`}
              />
              <div style={{textAlign: 'left'}}>
                <img
                  style={{ width: "2.5em" }}
                  src={`${process.env.PUBLIC_URL}/svgs/signal.svg`}
                />
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "2em",
                    marginBottom: ".5em",
                  }}
                >
                  <span style={{ textDecoration: "underline" }}>More.</span>
                  <span style={{ color: "#6D6B6B" }}> than just </span>
                  <span style={{ color: "#6D6B6B" }}>Wi-Fi.</span>
                </div>
                <div className="list-style">
                  <div className="list-h">Earn with Wi-Fi</div>
                  <ul>
                    <li>Capability to re-sell existing internet</li>
                  </ul>
                </div>
                <div className="list-style">
                  <div className="list-h">Do wonders with built in CRM</div>
                  <ul>
                    <li>Branded Wi-Fi journey </li>
                    <li>Real time offers, promotions on Wi-Fi </li>
                    <li>Customer usage reports </li>
                    <li>Manual and Auto SMS features</li>
                  </ul>
                </div>
                
                <YTWrapper for={site}
                  subPos="top"
                  url="https://youtu.be/cy4kWV786Mg"
                  thumb={ `${process.env.PUBLIC_URL}/thumbs/redefine_user_experiance_on_wifi.png` } >
                  View detailed video below
                </YTWrapper>
              </div>
            </div>
          </div>

          <div className="card india">
            <div className="head">
              <h1
                style={{
                  margin: "auto",
                  maxWidth: "fit-content",
                }}
              >
                <span>
                  Largest PDO solution in India
                  <br />
                  Trusted by 10,000+ Small Businesses
                </span>
                <hr
                  style={{
                    margin: ".15em",
                    padding: "0",
                    borderColor: "transparent",
                  }}
                />
                <span style={{ color: "#6D6B6B", fontWeight: "normal", fontSize: '.9em' }}>
                  Partnered with <b>Facebook</b> to set up <b>4,000+ PDOs</b>
                </span>
              </h1>
            </div>
            <div className={"details" + this.isSmallDevice}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                  textAlign: 'justify'
                }}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.5em",
                    marginBottom: ".5em",
                  }}
                >
                  <span>Core partner with TRAI in<br/> PDO Wi-Fi pilot</span>
                  <br />
                  <a
                    href="https://www.trai.gov.in/sites/default/files/WiFiPilotReport05042018.pdf"
                    target="_blank"
                    style={{ fontSize: '.8em'}}
                  >
                    Read more
                  </a>
                </div>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.5em",
                    marginBottom: ".5em",
                  }}
                >
                  <span>Backed by a team of <br/>IIT/IIM Professionals</span>
                  <br />
                  <a href="https://i2e1.com/tribe.html" target="_blank" style={{ fontSize: '.8em'}}>
                    Read more
                  </a>
                </div>
              </div>
              <div style={{position: 'relative'}}>
                <img
                  style={{ width: "24em", filter: 'grayscale(1)' }}
                  src={`${process.env.PUBLIC_URL}/india.png`}
                />
                <img
                src={incubated_logo}
                className="hide-in-small"
                style={{ width: "20rem", height: "auto", position: 'absolute', bottom: 0, right: 0 }}
              />
              </div>
              
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderSite = (param) => {
    switch (param) {
      case "partner":
        return this.renderPartner(param);
      default:
        return this.renderPdo(param);
    }
  };

  render = () => {
    let site = this.props.match.params.for;

    return (
      <>
        <Header for={site} selected="home" />
        {this.renderSite(site)}
        <Publishing for={site} />
        <Footer for={site} />
      </>
    );
  };
}
